/*  Core CSS file - standardizes styles across browsers.  
	Based on Yahoo's Developer Library CSS (reset.css and base.css) files.
	GSSI - Version 1.9 */

body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote {  
    margin:0; 
    padding:0; 
}

form { display: inline; }
table { border-collapse:collapse; border-spacing:0; } 
fieldset,img { border:0; } 
address,caption,cite,code,dfn,th,var { font-style:normal; font-weight:normal; } 
em { font-style: italic; }
h1,h2,h3,h4,h5,h6 { font-size:100%; } 
h1,h2,h3,h4,h5,h6,strong { font-weight:bold; } 
caption,th { text-align:left; } 
q:before,q:after { content:''; } 
abbr,acronym { border:0; } 


/* Some standard GSSI styling */
body { background-color: white; }
.c-w1 blockquote, .c-w1 ul, .c-w1 ol { margin: 0.25em 0 1em; } 
.c-w1 blockquote { margin-left: 2em; margin-right: 2em; }
.c-w1 ol, .c-w1 ul { margin-left:2em; } 
.c-w1 ol > li { list-style: decimal outside; } 
.c-w1 ul > li { list-style: disc outside; }
p,fieldset { margin: 0 0 1em; }
p,td { line-height: 1.5; }


/* Navigation lists <ul class="nav"> */
ul, li, ol, .nl, .nav, .nl li, .nav li, .nav ul { margin: 0; padding: 0; list-style: none; }

/* HTML5 Elements */
section, article, aside, footer, header, nav, hgroup {
  display:block;
}

/*clearfix*/
.cf:after {
  content: "";
  display: table;
  clear: both;
}

/* Accessability */
.element-invisible {
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}


/* Images
-------------------------------------------------------------- */

/*doc
---
title: Content Images
name: content_images
category: Content Classes
---

                                                        

<div class='entry-content'>
  <h3>Image class=alignleft</h3>
  <img src='../templates/images/sample.jpg' class='alignleft' />                
  <p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
  disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
  DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
  Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
   Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
   Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
   hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
    single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
    squid cliche flannel dreamcatcher.</p>


  <h3>Image class=alignright</h3>
  <img src='../templates/images/sample.jpg' class='alignright' />
  <p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
  disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
  DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
  Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
   Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
   Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
   hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
    single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
    squid cliche flannel dreamcatcher.</p>

  <h3>Image class=aligncenter</h3>
  <img src='../templates/images/sample.jpg' class='aligncenter' />
  <p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
  disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
  DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
  Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
   Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
   Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
   hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
    single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
    squid cliche flannel dreamcatcher.</p>
</div>

*/

.entry-content img {
  margin: 0 0 1em 0;
}
.alignleft,
img.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}
.alignright,
img.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}
.aligncenter,
img.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  text-align: center;
  margin-bottom: 1.5em;
}
.wp-caption img {
  border: 0 none;
  margin: 0;
  padding: 0;
}
.wp-caption p.wp-caption-text {
  margin: 0;
}
.wp-smiley { 
  max-height: 1em;
  margin:0 !important;
}
.gallery dl {
  margin: 0;
}
.gallery-caption {
  margin:-1.5em 0 0 0;
}


/* Pullquotes
-------------------------------------------------------------- */

blockquote.left {
  float: left;
  margin-left: 0;
  margin-right: 20px;
  text-align: right;
  width: 33%;
}
blockquote.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
  text-align: left;
  width: 33%;
}



