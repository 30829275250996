/*****************************
Theme Basics Settings
******************************/
html{margin-top:0 !important;}

.section-inner { 
	max-width: 1024px;

	margin: 0 auto;
	position: relative;

	@include bp(max-width, $bp-large){
		width:90%;
		position: static;
	}
}

.w-w2 {	
	@include bp(min-width, $bp-large){
		min-width: 970px;
	}  
}

/*******************************************
Header
********************************************/
/*flyout styles*/
#wrapper{
	overflow:hidden; 
	position: relative;
}
.w-w1{
	@include bp(max-width, $bp-large){
		width:100%;
		margin-left:0px;
		position: relative;
	}

	&.open{
		@include bp(max-width, $bp-large){
			margin-left:-258px;
			transition:all .5s; 
		}
	}
}


#header, .h-w1, .h-w2 {
 	height: 90px;

 	@include bp(max-width, $bp-large){
 		height:145px;
 	}

	@include bp(max-width, $bp-small){
		height:113px; 
	}
} /* Change to correct height */
#header {
	background:url(../img/bg/bg-header.png) top center repeat-x;
	z-index:100;

	@include bp(max-width, $bp-large){
		background-color:$sec-colour;
	}
	
	@include bp(max-width, $bp-small){
		background:$sec-colour url(../img/mobile/bg-header.png) top center repeat-x;
		background-size:100% 53px;
	}
}

.h-w2{
	z-index:100;
}

#mainnav, #topUtility, #logo {
	position: absolute;
}

#menu-toggle{
	display:none;
	position: absolute;
	right:20px;
	
	height:30px;
	line-height: 30px;
	padding-left:40px;
	width:1px;/*set to 1 to hide text*/
	overflow: hidden;
	font-size:15px;
	color:#fff;
	text-transform:uppercase;
	text-decoration:none;
	@include bp(max-width, $bp-large){
		display: block;
		top:33px;
	}@include bp(max-width, $bp-small){
		top:12px;
	}
	&.open{
		width:1px;
		font-size:0px;
	}


	&:after, & span:before, & span:after {
		border-radius: 1px;
		height: 3px; 
		width: 25px;
		background: #fff;
		display: block;
		content: '';
		position: absolute;
		right:7px;
		cursor: pointer;		
	}
	//top bar
	& span:before {
		top: 5px;
	}
	//middle bar
	& span:after {
		top: 12px;
	}
	//bottom bar
	&:after{
		top:19px;
	}
	 
	& span:before, & span:after {
		transition: all 500ms ease-in-out;
	}
		&.open:after {
			transform: translate(-999px, 0)
		}
	
	&.open span:before {
		transform: translate(0, 7px) rotate(45deg);
	}
	&.open span:after {
		transform: translate(0, 0px) rotate(-45deg);
	}
}

#navigation-wrap{
	@include bp(max-width, $bp-large){
		position: absolute;
		right:-258px;
		top:0px;
		width:258px;
		background:#333333;
		height:100%;
		overflow-y: scroll;
		padding:30px 10px 30px 10px;
		box-shadow: inset 5px 1px 10px 0px rgba(0,0,0,.65);
		z-index: 999;
	}
}

#mainnav {
	z-index: 20;
	bottom: 0px;
	// right:135px;
	left:0px;
	width:100%;
	padding-left:220px;
	@include bp(max-width, $bp-large){
		position:static;
		padding-top:60px;
		width:100%;
		padding-left:0px;
	}
	@include bp(max-width,$bp-small){
		padding-top:74px;
	} 

	&.open{
		@include bp(max-width, $bp-large){
			right:0px;
		}
	}
	
	li { 
		float: left;
		margin-left:20px;
		@include bp(max-width, $bp-large){
			float:none;
			text-align: center;
			margin-bottom: 20px;
			margin-left:0px;
		}

		a{
			color:#fff;
			font-size:15px;
			font-style: italic;
			text-decoration: none;
			font-weight: normal;
			display: block;
			height:46px;
			@include bp(max-width, $bp-large){
				font-size:1.7rem;
				height:auto;
			}

			&:hover, &.sfSel{color:$prim-colour;}
		}

		&.hasChildren{
			> a{

				&:after{
					display: none;

					@include bp(max-width,$bp-large){
						display: none;
						content:'';
						background:url(../img/mobile/menu-toggle.png) 0 -2px no-repeat;
						
						width:25px;
						height:15px;
						background-size:25px 60px;
					}
				}
			}

			&.open > a:after,
			a.sfSel:after{
				background-position:0 -40px;
			}

		}

		

		ul{
			display: none;
			position: absolute;
			left:0px;
			top:46px;
			width:100%;
			text-align: center;
			background:#414242;
			background:rgba(65,66,66,.6);
			@include bp(max-width, $bp-large){
				position: static;
				background:#3f3f3f;
				margin: 15px -20px;
				padding:10px 0;
				width: auto;
			}
			&:before{
				position: absolute;
				left: -100%;
				top: 0px;
				content: "";
				width: 100%;
				height: 100%;
				background:#414242;
				background:rgba(65,66,66,.6);
			}

			&:after{
				position: absolute;
				right: -100%;
				top: 0px;
				content: "";
				width: 100%;
				height: 100%;
				background:#414242;
				background:rgba(65,66,66,.6);
			}


			li{
				float:none;
				display: inline-block;
				margin:0 8px;
				@include bp(max-width, $bp-large){
					display: block;
				}

				a{
					padding:18px 0;
					height:auto;
					position: relative;	
					@include bp(max-width, $bp-large){
						padding:12px 0;
					}
				}
			}

			
		}

		&:hover ul{
			@include bp(min-width, $bp-large){
				display: block;
			}
		}

		/*mobile show if selected */
		.sfSel + ul,
		&.open > ul{
			@include bp(max-width, $bp-large){
				display: block;
			}
		}

	}
}

#logo {
	//top: 5px;
	left: 0px;
	z-index:100;
	top:29px;
	@include bp(max-width, $bp-small){
		//top:6px;
		left:23px;  
		display: block;
		width:113px;
		top:14px;
	}

}
#topUtility {
	bottom: 23px;
	right:30px;
	z-index:200;
	@include bp(max-width, $bp-large){
		position:static;
		width:100%;
		margin-top:20px;
		text-align: center;
	}
	li{
		display: inline-block;
		vertical-align: top;
		margin-left:5px; 
		a{
			display: block;
			width:25px;
			padding-top:25px;
			height:1px;
			overflow: hidden;
			transition:none;
			@include bp(max-width, $bp-large){
				width:37px;
				padding-top:38px;
				background-size:37px 38px;
			}

			&:hover{
				@include bp(min-width, $bp-large){
					background-position:0px -42px;
				}
			}
		}

		&.fb a{
			background-image:url(/build/img/bg/icon-facebook.png);
			@include bp(max-width, $bp-large){
				background-image:url(/build/img/mobile/icon-facebook.png);
			}
		}
		&.tw a{
			background-image:url(/build/img/bg/icon-twitter.png);
			@include bp(max-width, $bp-large){
				background-image:url(/build/img/mobile/icon-twitter.png);
			}
		}
		&.ig a{
			background-image:url(/build/img/bg/icon-instagram.png);
			@include bp(max-width, $bp-large){
				background-image:url(/build/img/mobile/icon-instagram.png);
			}
		}

		&.lnkin a{ 
			background-image:url(/build/img/bg/icon-linkedin.png); 
			@include bp(max-width, $bp-large){
				background-image:url(/build/img/mobile/icon-linkedin-mb.png);
			}
			&:hover{ 
				@include bp(min-width, $bp-large){
					background-position:0px -44px; 
				}
			}
		}
	}
}

#search-toggle{
	width:25px;
	height:26px;
	border:none;
	font-size:0px;
	cursor:pointer;
	background:url(/build/img/bg/icon-search.png) 0 2px;
	position:absolute;
	bottom:25px;
	right:0px;
	z-index:200;
	transition:none;
	&.active{
		background:url(/build/img/bg/icon-close-search.png) 0 2px;
		z-index: 600;
	}

	@include bp(max-width, $bp-large){
		display: none;
	}
}

#searchArea{
	position: absolute;
    right: 0;
    top: 0px;
    width: 23px;
    z-index: 200;
    text-align: right;
    transition:all .5s;
    width: 100%;
    padding: 102px 20px 20px 20px;
    background: #ccc;
    background:rgba(50,50,50,0.8);
    display: none;
	@include bp(max-width, $bp-large){
		top:22px;
		right:5%;
		width:90%;
		display: block;
		padding:0px;
	}
	&.active{
		display: block;
	}

	&:before, &:after {
	    position: absolute;
	    left: -100%;
	    top: 0;
	    content: '';
	    width: 100%;
	    height: 100%;
	    background: #252525;
	    background: rgba(50,50,50,0.9);
	}

	&:after{
		left:auto;
		right:-100%
	}

	.sfsearchBox{
		position: relative;
		margin:0px;
	}

	input[type='text']{
		background:#fff;
		border:none;
		box-shadow:inset 1px 1px 7px 0px rgba(0, 0, 0, 0.4); 
		height:60px;
		line-height: 60px;
		color:#fff;
		font-style: italic;
		font-size:24px;
		color:#666666;
		@include bp(max-width, $bp-large){
			font-size:1.5rem;
			height:40px;
			line-height: 40px;
		}
		&:focus {
		    border-color: #66afe9;
		    outline: 0;
		    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
		    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
		}
	}

	input[type='submit']{
		width:65px;
		height:60px;
		border:none;
		font-size:0px;
		cursor:pointer;
		background:url(/build/img/bg/icon-search-large.png);
		position:absolute;
		top: 0px;
   		right: 0px;
   		margin:0;
		@include bp(max-width, $bp-large){
			width:40px;
			height:40px;
			background-size:cover;
		}
	}

}
// #searchArea{
// 	position: absolute;
// 	right:0px;
// 	bottom:22px;
// 	width:23px;
// 	height:40px;
// 	overflow:hidden;
// 	z-index:200;
// 	transition:width .75s;
// 	@include bp(max-width, $bp-large){
// 		top:22px;
// 		right:5%;
// 		width:90%;

// 	}
// 	&.active {
// 		@include bp(min-width, $bp-large){
// 			width:300px !important;
// 			padding-left:30px;
// 			background:#fff;
// 		}
// 	}
	
// 	fieldset{
// 		margin:0px;
// 	}

// 	input[type='text']{
// 		position:absolute;
// 		top:2px;
// 		right:23px;
//   		height:40px;
//   		line-height: 30px;
//   		padding:5px 10px;
//   		border:none;
//   		background:#fff;
//   		transition:all .75s;
// 		@include bp(max-width, $bp-large){
// 			display: block;
// 			width:100% !important;
// 			right:0px;
// 			background:url(../img/mobile/search.png) right 0 no-repeat !important;
// 			background-size:100% 100% !important;
// 			font-size:1.5rem;
// 		}
// 	}

// 	&.active input[type='text']{
// 		padding:5px 20px;
// 		display: block;
// 	}

// 	&:hover input[type='text']{
// 		right:0px; 
// 	}

// 	input[type='submit']{
// 		width:25px;
// 		height:26px;
// 		border:none;
// 		font-size:0px;
// 		cursor:pointer;
// 		background:url(/build/img/bg/icon-search.png);
// 		position:absolute;
// 		top:7px;
// 		right:0px;
// 		@include bp(max-width, $bp-large){
// 			background:none;
// 			width:30px;
// 			height:40px;
// 		}
// 	}

// 	&.active input[type='submit']{ 
// 		background-position:0px -42px;
// 	}

// }

#leftCTA{
	position: fixed;
	right:0px;
	top: 50%;
    transform: translateY(-50%);
	width:126px;
	z-index:200;
	@include bp(max-width, $bp-large){
		position:absolute;
		top:42px;
		width:100%;
		transform: none;
		z-index: 1;
	}
	@include bp(max-width, $bp-small){
		top:27px;
	}

	ul{
		float:right;
		@include bp(max-width, $bp-large){
			float:none;
		}
	}

	li{
		margin-bottom: 2px;
		@include bp(max-width, $bp-large){
			width:33.33%;
			float:left;
		}
		&:nth-child(2){
			@include bp(max-width, $bp-large){
				margin:0 1%;
				width:31.33%;
			}
		}
	}
	a{
		display: block;
		width:90px;
		float:right;
		padding-top:119px;
		height:1px;
		overflow: hidden;
		background-color:#ef460f;
		background-color:rgba(239,70,15,.85);
		background-repeat:no-repeat;
		transition: all .5s;
		background-position:left 0;
		@include bp(max-width, $bp-large){
			width:100%;
			padding-top:59px;
			background-position:center 0;
			background-color:#fb4b02;
			background-size:105px 60px;
		}
		&:hover{
			width:126px;
			background-color:rgba(239,70,15,1);
			@include bp(max-width, $bp-large){
				width:100%;
			}
		}
	}

	.find-lot a{
		background-image:url(../img/bg/cta-findalot.png);
		@include bp(max-width, $bp-large){
			background-image:url(../img/mobile/cta-findalot.png);
		}
	}
	.pay-fine a{
		background-image:url(../img/bg/cta-payafine.png);
		@include bp(max-width, $bp-large){
			background-image:url(../img/mobile/cta-payafine.png);
		}
	}
	.appeal-fine a{
		background-image:url(../img/bg/cta-appealafine.png);
		@include bp(max-width, $bp-large){
			background-image:url(../img/mobile/cta-appealafine.png);
		}
	}

	.live-chat-cta{
		position: relative;
		clear:both;
		@include bp(max-width, $bp-large){
			display: none;
		}
		a{
			background-image:url(../img/bg/cta-livehelp.png);
		
		}
	}

	#LP_DIV_1354819247501,
	#LP_DIV_1399589564950,
	#LP_DIV_1464253966112,
	#LP_DIV_1464166936103,
	#LPMcontainer-1471647853946-0{
		display: none !important;
	}

	.live-chat-cta > div:nth-child(2){
		display: inline-block !important;
		position: absolute !important;
		margin:0 !important;
		padding:0 !important;
		top:0;
		left:0;
		max-width: 100%;
		opacity: 0;
		
	}

	.live-chat-cta .LPMcontainer{
		height:125px !important;
	}
}

body > .LPMoverlay{ 
	display:none !important;
	@include bp(max-width, $bp-large){
		width:320px !important;
		height:43px !important;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.74);
		background-image:url(../img/livechat.png);
	
		display: block !important;
		top:auto !important;
		bottom:0px !important;
		left:50% !important; 
		margin-left:-160px !important;
	}

	img{display:none;}
}

/*******************************************
Content
********************************************/
#main {
	@include bp(max-width, $bp-large){ 
		padding-bottom:120px;
	}
}
    
.m-w1 { 
	@include cf();
}

#banner{
	height:430px;
	margin-top:-116px;
	position:relative;
	z-index: -1;
	@include bp(max-width, $bp-small){
		margin-top:0px;
		height:143px;
		display: none;
	}

	img{
		position:absolute;
		left:50%;
		margin-left:-960px;
		top:0px;
		max-width: 1920px;
		
		@include bp(max-width, $bp-small){
			// margin-left:-320px;
			// width:640px;
			position:static;
			margin:0px;
			width:100%;
		}
	}

	.mobile img{
		display: none;
		@include bp(max-width, $bp-small){
			display: block;
		}
	}
	.desktop img{
		display: block;
		@include bp(max-width, $bp-small){
			display: none;
		}
	}
}

 
#subnav{
	position:absolute;
	top:398px;
	left:0px;
	width:100%;
	border-bottom:1px solid #c4c6ba;
	text-align:center;

	@include bp(max-width, $bp-large){
		top:460px;
	}
	@include bp(max-width, $bp-medium){
		position:static;
		padding:30px 0;
		border-bottom:none;
		border-top:2px solid #c4c6ba;
	}

	.m-subnav-title{
		display: none;
		@include bp(max-width, $bp-medium){
			display: block;
			font-size:2.0rem;
			font-style:italic;
			color:$prim-colour;
			margin-bottom: 20px;
		}
	}

	li{
		display: inline-block;
		vertical-align: top;
		float:none;
		margin:0 15px;
		@include bp(max-width, $bp-medium){
			display: block;
			margin: 0;
		}
		a{
			font-style:italic;
			color:#333;
			padding:20px 0;
			display: block;
			text-decoration:none;
			font-size:16px;
			font-size:1.6rem;
			@include bp(max-width, $bp-medium){
				padding:10px 0;
				font-size:1.7rem;
			}

			&.sfSel, &:hover{
				color:$prim-colour;
				font-weight: normal;
			}
		}
	}

}

#content {
	margin-top:58px;
	padding-top:45px;
	padding-bottom:80px;
	padding-left:80px;
	max-width:850px;
	overflow:auto; /*for iframes */
	@include bp(max-width, $bp-large){
		margin-top:0px;
		padding-left:0px;
		padding-bottom:0px; 
		padding-top:85px; /*to accommodate subnav */
	}
}

/* Subnav appears after content-body in markup, 
	but often is shifted to the left */

/*******************************************
CTAs
********************************************/

#ctas {
	width:100%;
	position:relative;
	z-index:10;
	box-shadow: 0px 11px 13px 0px rgba(0,0,0,0.16);
	.sf_2cols_1_50, .sf_2cols_2_50{
		@include bp(max-width, $bp-small){
			float:none;
			width:100%;
		}
	}
	.sf_colsIn{
		margin:0px;
	}

	img{display:block;} 
}

.bottom-ctas{
	display:none;
	padding:54px 0 160px 0;
	background:#ecedee;
	.section-inner{
		@include bp(max-width, $bp-small){
			width:94%;
		}
	}
	.sf_3cols_2_34{
		width:33%;
	}
	.sf_colsOut .sf_colsIn{
		margin:0 5px;
	}
	a{
		display: block;
		height:368px;
		
		overflow: hidden;
		box-shadow: 0px 1px 15px 0px rgba(0,0,0,0.5);
		position:relative;
		text-align: center;
		font-size:22px;
		font-style: italic;
		font-weight: 500;
		text-decoration: none;
		color:#666666;
		padding:66px 22% 0 22%;  
		background-position:center top;
		@include bp(max-width, $bp-large){
			padding:66px 5% 0 5%;  
		}
		@include bp(max-width, $bp-small){
			border-top:4px solid $prim-colour;
			padding:10px 5% 0 5%;
			font-size:11px;
			height:156px;
			background-size:100% auto;
			background-repeat:no-repeat;
			background-position:bottom center;
			background-color:#fff;
			line-height: 13px;
		}
		&:hover:after{
			content:'';
			display: block;
			width:100%;
			height:100%;
			position: absolute;
			left:0px;
			top:0px;
			background:url(../img/bg/cta_hover.png);
			background-size:100% 100%;
		}

		span{
			display: block;
			margin:22px auto 0 auto;
			color:#fff;
			background-color:$prim-colour;
			border-radius:10px;
			font-size:14px;
			width:100px;
			line-height: 27px;
			@include bp(max-width, $bp-small){
				margin-top:10px;
				font-size:10px;
				width:74px;
				line-height: 17px;
			}
		}
	}

	.cta-electric{
		background-image:url(../img/cta/cta-electric.jpg);
		@include bp(max-width, $bp-small){
			background-image:url(../img/mobile/cta-electric.jpg);
		}
	}
	.cta-film{
		background-image:url(../img/cta/cta-film.jpg);
		@include bp(max-width, $bp-small){
			background-image:url(../img/mobile/cta-film.jpg);
		}
	}
	.cta-reserve{
		background-image:url(../img/cta/cta-reserve.jpg);
		@include bp(max-width, $bp-small){
			background-image:url(../img/mobile/cta-reserve.jpg);
		}
	}
}


/*******************************************
Footer
********************************************/
.top-nav-btn{
	position:absolute;
	top:-133px;
	left:50%;
	margin-left:-19px;
	display:block;
	height:1px;
	overflow:hidden;
	width:40px;
	padding-top:45px;
	background:url(../img/bg/btn-top.png);
	@include bp(max-width, $bp-small){
		background:url(../img/mobile/btn-top.png);
		background-size:41px 45px;
		top:-82px;
	}
}

#footer { 
	clear:both;
	position:relative;
	height:130px;
	padding:50px 0 110px 0;
	font-size:14px;
	background:url(../img/bg/bg-footer.png) center top;
	text-align:center;
	font-style: italic;
	@include bp(max-width, $bp-small){
		height:auto;
		background:url(../img/mobile/bg-footer.png) center top repeat-x;
		background-size:100% 100%;
		font-size:12px;
		padding:28px 0 50px 0; 
	}
	&:before{
		content:'';
		position:absolute;
		top:-20px;
		left:50%;
		margin-left:-20px;
		display:block;
		height:1px;
		overflow:hidden;
		width:40px;
		padding-top:40px;
		background:url(../img/bg/icon-park.png);
		@include bp(max-width, $bp-small){
			width:30px;
			padding-top:29px;
			margin-left:-15px;
			top:-15px;
			background-size:100%;
		}
	}

	a{
		color:#fff;
		text-decoration: none;

		&:hover{text-decoration:underline;}
	}
}

#foot-sitemap{
	li{
		display: inline-block;
		margin:0 5px 10px 5px;
	}
}
#foot-copy{
	margin-right:15px;
	margin-bottom:15px;
}
#foot-copy, #foot-credit{
	display:inline-block;
	vertical-align:top;
	font-size:13px;
	color:#999999;
	
	@include bp(max-width, $bp-small){
		margin-bottom: 10px;
		font-size:12px;
	}
}

#liveChat{
	position: fixed;
	left:38px;
	bottom:0px;
	background:#fff url(../img/bg/icon-chat.png) right center no-repeat;
	border-radius:10px 10px 0 0;
	width:322px;
	padding-left:15px;
	height:42px;
	line-height:42px;
	font-size:15px;
	font-style:italic;
	color:#333333;
	font-weight:500;
	@include bp(max-width, $bp-large){
		left:50%;
		margin-left:-160px;
	}
}

/***********************************
Brand Partners
**********************************/

.flex-ctas{
	display:none;
}
.overview-logos{
	@extend .section-inner;
	padding-top:40px;
	padding-bottom:40px;
	text-align: center;
	font-style:italic;
	color:#333333;
	font-weight: 500; 
	

	.sf_colsOut{
		max-width: 1022px;
		margin: 0 auto;
		position: relative;
		padding-left:80px;
		height:auto;

		@include bp(min-width, $bp-xtr-large){
			padding-left:0px;
		}
		@include bp(max-width, $bp-large){
			width:90%;
			position: static;
		}
		@include bp(max-width,$bp-small){
			padding-left:0px;
		}
	}

	p{
		
	}

	ul{
		@include bp(max-width,$bp-small){
			text-align: left;
		}
	}
	li{
		display: inline-block;
		vertical-align: top;
		width:24%;
		height:75px;
		padding:0 2%;
		overflow: hidden;
		margin-top: 35px;
		@include bp(max-width, $bp-large){
			width:32%;
			padding:0px;
		}
		a{
			display: block;
			height:100%;
			margin-top:0px;
			&:after{display:none;}
		}
		
		img{
			height:200%;
			@include bp(max-width,$bp-small){
				position:relative;
				top:-100%;
			}
		}

		&:hover img{
			position:relative;
			top:-100%;
		}
	}
}

/*********************************************
News
*********************************************/

.c-w1 .sfnewsList{
	margin:0px;

	li{
		list-style: none;
		margin-bottom: 0px;
		padding:20px 0;
		border-bottom:1px solid $prim-colour;

		.sfnewsTitle a{
			text-decoration: none;
		}
	}
}

.sfnewsAuthorAndDate{
	color:$prim-colour;
	font-weight:600;
	margin-bottom:10px;
}


/*********************************************
Custom Forms
*********************************************/

.orangetext{
	color:#F57F29;
}

.validation-summary{
	color:red;
}

.error{
	ul{
		margin-top:0;
		margin-bottom: 0;
	}
}

.apply-online-form{
	ul.form-steps{
		margin:0px;

		> li{
			list-style: none;
			float:left;
			width:33%;
			margin:0px;
			padding:25px 0;
			text-align: center;

			.selected{
				font-weight: bold;
			}
		}
	} 

	table{
		border-top:none;

		tr{
			border-color:#ccc;
		}

		.rcTable tr{
			border:none;
		}

		td{
			width:50%;
			vertical-align: middle;
			@include bp(max-width, $bp-small){
				vertical-align: top;
			}
		}

		select, input[type='text'], .rcInputCell input.riTextBox{
			width: 90% !important;
			margin-right: 2%;
			height: 33px;
			font-size: 14px;
			padding: 4px 15px;
			line-height: 20px;
		}

		input[type='text']{
			height: 50px;
			font-size: 20px;
			padding: 10px 15px;
			line-height: 20px;
		}

		.start-date{
			& > div, & > div > table{
				width:90% !important;
			}
		}

		.emailhidden{
			margin:20px 0;

			.tooltipContent{
				line-height: 1.5em;
				padding:0 10px;
			}
		}
	}

	input[type='submit']{
		@include btn;
		border:none;
	}
}

table.form_table, table.apply-table{
	border-top:none;
	width:100%;

	tr{
		border-color:#ccc;
		border:none;
	}

	td{
		width:50%;
		vertical-align: top;
		@include bp(max-width, $bp-small){
			vertical-align: top;
			padding:10px 1px;
			display: block;
			width:100% !important;
		}
	}

	select, input[type='text']{
		width: 90% !important;
		margin-right: 2%;
		margin-bottom:0px;
		height: 33px;
		font-size: 14px;
		padding: 4px 15px;
		line-height: 20px;
	}

	input[type='radio']{margin-right:10px;}

	br{
		display: none;
	}

	.reasoncancelling br{
		display: block;
		margin:5px;
	}

	input[type='submit']{
		@include btn;
		border:none;
	}
} 

.monthly-pay input.txtEmail{ text-transform: lowercase; } 

/******capthca ************/

table.form_table .RadCaptcha,
.apply-table .RadCaptcha{
	text-align:right; 
	padding-right: 31px;

	&.rcInvalid > span{
		color: red;
	    font-weight: normal;
	    font-size:16px;
	    display: block;
	    margin-bottom: 15px;
	}

	img{
		float:right;
		margin-bottom: 10px;
	}

	p{
		width: 366px;
    	clear:both;
        display: block;
   		 float: right;
		margin-bottom: 10px;

		@include bp(max-width, $bp-small){
			max-width: none;
			width:100%;
		}

		& > input{
			@include bp(max-width, $bp-small){
				width:100% !important;
			}
		}
	}


	label{
		display: block;
		margin-top: 10px;
	}
}

.reasoncancelling label{
	margin-bottom:10px;
}

/********* Pay Monthly Parking Tooltipp *************/
.showTip{
	position: relative;
	&:hover{
		&:after{
			content:'CVV: Its a 3 or 4 digit number at the back of your credit card';
			display:block;
			width:300px;
			height:auto;
			padding:10px;
			background:#ccc;
			position:absolute;
			top:20px;
			left:0px;
			font-size:13px;
			border:1px solid #000;

			@include bp(max-width, $bp-small){
				width:auto;
			}
		}
	}
}

/*******************************
Events
*******************************/
.sfcalendarWrp table td{
	padding:0;  
}

.sfcalendarWrp .rsContentScrollArea{
	height:auto !important;
	overflow:normal;
}

.sfcalendarSidebar{display:none;}

/*********************************************
Editor fixes
*********************************************/
.sfPageEditor{
 
 	.mobile{
 		display: block;
 	}

 	#header{
 		min-height:500px;
 	}

	 #banner{
		z-index:0;
		height:auto;

		.image-wrapper{
			height:500px;
		}
	}

	.side-accord{
		height:auto;
	}

	#leftCTA{
		display: none;
	}

	.banner-content-overlay{
		position: static !important;
	}
}


/*******************************************************
Search
********************************************************/

.sfsearchResultTitle{
	margin-top:25px;
}

/*******************************************************
Calendar
********************************************************/

.c-w1 .RadScheduler_Metro .rsHeader{
	background:$prim-colour;
	border:none;

	h2{
		color:#fff;
	} 

	ul{
		margin:0px;

		a{
			border-color:#fff;
		}
	}


}


/********************************************************
Grid
********************************************************/

.c-w1 .sf_colsOut{
	@include bp(max-width, $bp-small){
		width:100%;
		float:none;
	}
}


/***************************************
Live Chat
****************************************/
#launcher.zEWidget-launcher{
	@include bp(max-width, $bp-large){
		position:fixed !important;
		top:auto !important;
		bottom:20px !important;
		right:20px !important;
		margin:0 !important;
		width:45px !important;
		height: 45px !important;
		padding:0 !important;
		min-height: 0 !important;
		transition: all .5s !important;
		opacity: 0 !important;
	}
}
#webWidget{
	@include bp(max-width, $bp-large){
		margin:0 !important;
	}
}
#leftCTA .live-chat-cta{
	display:none;
	@include bp(max-width, $bp-large){
		display: block;
		position:fixed;
		bottom:20px;
		right:20px;
		width:45px;
		height: 45px;
		background: #fb4b02 url(/img/mobile/chat-btn.png) no-repeat center;
		box-shadow:0px 0px 5px 1px rgba(0,0,0,0.5);
		display: block;
		margin:0;

		a{
			padding-top:45px;
			background:none;
		}
	}
}
