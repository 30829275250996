/*--------------------------------------------------------------
# Tabs
--------------------------------------------------------------*/
@include bp(min-width, $bp-small){
  .tabs-accordion{
    margin:20px 0;
  }
  .tabs-accordion ul.tab-header{
    @include cf;
    margin:0px;
    background:url(/img/bg/grid-pattern-fine.png);
    width:100%;
    display: block;
    border-top:5px solid $prim-colour;
  }
    .tabs-accordion ul.tab-header li{
      list-style: none;
      display: inline-block;
      height:70px;
      padding:20px 25px;
      margin:0px;
      line-height: 30px;
      font-size:18px;
      font-size:1.8rem;
      font-weight: 500;
      text-transform: uppercase;
      color:#fff;
      cursor: pointer;
      text-decoration: none;
      transition:all .25s;
    }
      .tabs-accordion .tab-header li[aria-selected='true']{
        background:$prim-colour;
        color:#fff;
        outline:none;
      }

  .tabs-accordion .tab-content{
    background:#f5f6f6;
  }
    .tabs-accordion .tab-title{display: none;}
    .tabs-accordion .tab-box{
      display:block;
      width: 100%;
      clear:both;
      padding:20px;
    }
      .tabs-accordion .tab-box[aria-hidden='true']{
        display: none;
      }
}

@include bp(max-width, $bp-small){
  .tabs-accordion ul.tab-header{
    display: none;
  }

  .tabs-accordion .tab-content{
    margin:20px 0;
  }
    
    .tabs-accordion .tab-box{
      display:block;
      width: 100%;
      margin-bottom: 30px;
    }
      .tabs-accordion .tab-box .tab-title{
        border-top:5px solid $prim-colour;
        background:url(/img/bg/grid-pattern-fine.png);
        background-size: 225px auto;
        color:#fff;
        display: block;
        height:50px;
        line-height: 25px;
        padding:10px 15px;
        cursor: pointer;
        position: relative;
        text-transform: uppercase;
        font-size:15px;

      }
        .tabs-accordion .tab-box .tab-title:after{
          content:'';
          display: block;
          position: absolute;
          right:20px;
          top:14px;
          width:20px;
          height:18px;
          background:url(../img/mobile/toggle-accordion.png) 0 0 no-repeat;
          background-size:19px 97px;
          -webkit-border-radius:50%;
          border-radius:50%;
        }
          .tabs-accordion .tab-box .tab-title.open:after{
            background-position:0 bottom;
          }
      .tabs-accordion .tab-box .inner{
        max-height: 0px;
        overflow: hidden;
        transition:all .5s;
      }

      .tabs-accordion .tab-box .inner[aria-hidden='false']{
        max-height:2000px;
        padding:0 0 20px 0;
      }
}


/***************************
Accordions
***************************/

.sflistExpandAllLnkWrp {
  @include cf;

  a{
    float:right;
    display: block;
    text-align: left; 
    text-decoration: none;
    color:#fff;
    background:url(/img/bg/grid-pattern-fine.png);
    background-size: 225px auto;
    line-height: 25px;
    padding:10px 55px 10px 15px;
    position: relative;
    text-transform: uppercase;
    font-size:15px;
    margin: 0 0 15px 0;

    &:after{
      content:'';
      display: block;
      position: absolute;
      right:20px;
      top:14px;
      width:20px;
      height:18px;
      background:url(../img/mobile/toggle-accordion.png) 0 0 no-repeat;
      background-size:19px 97px;
      -webkit-border-radius:50%;
      border-radius:50%;
    }

    &.sflistCollapseAllLnk{
      &:after{
        background-position:0 bottom;
      }
    }
  }
}

.sfexpandableListWrp{
  margin:20px 0;

  > .sflistList{
    margin:0px;

    > .sflistitem{
      list-style:none;
      

      .sflistItemTitle{
        margin-bottom: 0px;

        &:after{
          display: none;
        }
      }
   

      h3 a{
        display: block;
        text-align: left; 
        text-decoration: none;
        color:#fff;
        border-top:5px solid $prim-colour;
        background:url(/img/bg/grid-pattern-fine.png);
        background-size: 225px auto;
        line-height: 25px;
        padding:10px 48px 10px 15px;
        position: relative;
        text-transform: uppercase;
        font-size:15px;

        &:after{
          content:'';
          display: block;
          position: absolute;
          right:20px;
          top:14px;
          width:20px;
          height:18px;
          background:url(../img/mobile/toggle-accordion.png) 0 0 no-repeat;
          background-size:19px 97px;
          -webkit-border-radius:50%;
          border-radius:50%;
        }
      }

      .sflistListItemExpanded:after{
        background-position:0 bottom;
      }

      .sflistItemContent{
        padding-bottom:20px;
        transition:all .75s;
        // max-height: 0px;
        
        &.sflistItemContentExp{
          // max-height:600px;
        }
      }
    }
  }
}
    
    
      .accordion .tab-box .tab-title{
        border-top:5px solid $prim-colour;
        background:url(/img/bg/grid-pattern-fine.png);
        background-size: 225px auto;
        color:#fff;
        display: block;
        height:50px;
        line-height: 25px;
        padding:10px 15px;
        cursor: pointer;
        position: relative;
        text-transform: uppercase;
        font-size:15px;

      }
        .accordion .tab-box .tab-title:after{
          content:'';
          display: block;
          position: absolute;
          right:20px;
          top:14px;
          width:20px;
          height:18px;
          background:url(../img/mobile/toggle-accordion.png) 0 0 no-repeat;
          background-size:19px 97px;
          -webkit-border-radius:50%;
          border-radius:50%;
        }
          .accordion .tab-box .tab-title.open:after{
            background-position:0 bottom;
          }
      .accordion .tab-box .inner{
        max-height: 0px;
        overflow: hidden;
        transition:all .5s;
      }

      .accordion .tab-box .inner[aria-hidden='false']{
        max-height:2000px;
        padding:0 0 20px 0;
      }