html {
  box-sizing: border-box;
  font-size: 62.5%; /* Sets up the Base 10 stuff */
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}    

body{
  font-size:18px;
  font-size:1.8rem;
  line-height: 1.28em;
  font-family: 'Ubuntu', Arial, sans-serif;
  color:#666666;
  @include bp(max-width, $bp-small){
    font-size:1.5rem;
  }
}
/*doc
---
title: Background Colors
name: background
category: Base CSS
---

We have a few background colors that can be used in various contexts.
These are not for use as the entire page background but instead for
specific components and modules on the page.

<div class="line txtC">
    <div class="col cols6 ">
      <div class="docSwatch primary-color"></div>
      <code>primary-color</code>
    </div>
    <div class="col cols6 ">
      <div class="docSwatch secondary-color"></div>
      <code>secondary-color</code>
    </div>
    <div class="col cols6 ">
      <div class="docSwatch accent-color"></div>
      <code>accent-color</code>
    </div>
    <div class="col cols6 lastCol">
      <div class="docSwatch  sec-accent-color"></div>
      <code>sec-accent-color</code>
    </div>
</div>
*/
 
.primary-color{
	background:$prim-colour;
}

.secondary-color{
	background:$sec-colour;
}

.accent-color{
	background:$acc-colour;
} 

.sec-accent-color{
	background:$sec-acc-colour;
} 

/*doc
---
title: Typography
name: typography
category: Base CSS
---

These are the heading sizes that you can use site wide.

<h1>This is a h1 tag 36px Lorem Ipsum</h1>

<h2>This is a h2 tag  25px Lorem Ipsum</h2>

<h3>This is a h3 tag  20px Lorem Ipsum</h3>

<h4>This is a h4 tag  18px Lorem Ipsum</h4>


<p>This is an example of our base font style 16px. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>


*/

h1, .sflistItemTitle {
	font-size:60px;
  font-size:6.0rem;
	color:#666666;
	line-height: 1.25em;
	margin-bottom: 0.7em;
  text-align: center;
  position:relative;
  padding-bottom:20px;
  font-style: italic;
  @include bp(max-width, $bp-small){
    font-size:2.7rem;
  }

  &:after{
    content:'';
    position:absolute;
    bottom:0px;
    left:50%;
    margin-left:-50px;
    display: block;
    width:100px;
    height:5px;
    background:$prim-colour;
    @include bp(max-width, $bp-small){
      width:50px;
      margin-left:-25px;
      height:3px;
    }
  } 
}

h2 {
	font-size:30px;
  font-size:3.0rem;
	color:#333333;
	font-weight:normal;
	line-height: 1.1em;
	margin-bottom: 0.67em;
  @include bp(max-width, $bp-small){
    font-size:2.2rem;
  }
}

h3 {
  font-size:21px;
  font-size:2.1rem;
  color:#333333;
  font-weight:normal;
  line-height: 1.1em;
  margin-bottom: 0.67em;
  @include bp(max-width, $bp-small){
    font-size:1.7rem;
  }
}

p{
  margin:0 0 1.38em 0;
}

/*doc
---
title: Lists
name: lists
category: Base CSS
---

These list styles are only applied in the content area. They are targeted
using the .c-w1 class.

<div class='c-w1'>
<ul>
  <li>List Item</li>
  <li>List Item</li>
  <li>List Item
      <ul>
        <li>List Item</li>
        <li>List Item</li>
        <li>List Item</li>
      </ul>
  </li>
  <li>List Item</li>
  <li>List Item</li>
</ul>

<ol>
  <li>List Item</li>
  <li>List Item</li>
  <li>List Item
      <ol>
        <li>List Item</li>
        <li>List Item</li>
        <li>List Item</li>
      </ol>
  </li>
  <li>List Item</li>
  <li>List Item</li>
</ol>
</div>

*/

.c-w1 ul, .c-w1 ol{
  margin:1em 0 2em 2em;
}

.c-w1 ul > li{
  margin-bottom: 10px;
  list-style:disc;
}

.c-w1 ol > li{
  margin-bottom: 10px;
}

/*doc
---
title: Buttons
name: buttons
category: Base CSS
---

```html_example_table                                                          

<button class="btn">Default</button>

<button class="btn btnSecondary">Secondary</button>

<button class="btn btnTertiary">Secondary</button>                      

```
*/



.btn{
  @include btn;
}

.btnSecondary{
  background:green;
  color:#fff;
}

.btnTertiary{
  background:FireBrick;
  color:#fff;
}

img{
  max-width: 100%;
}

/*doc
---
title: Forms
name: forms
category: Forms
---

Basic Form Styles. If in Wordpress, use to override Gravity Forms default styling.

<form>
          <div class='table-row'>
            <label class='center' for='field1'>Full Name</label>
            <div class='input-holder'>
              <input type='text' id='field1' name='field1' />
            </div>
          </div>
          <div class='table-row'>
            <label class='center' for='field2'>Email</label>
            <div class='input-holder'>
              <input type='text' id='field2' name='field2' />
            </div>
          </div>
          <div class='table-row'>
            <label class='center' for='field3'>Phone Number</label>
            <div class='input-holder'>
              <input type='text' id='field3' name='field3' />
            </div>
          </div>

          <div class='table-row'>
            <legend>Select a Choice</legend>
            
              <div class='input-holder'>
                <input type="radio" value='First Choice' id='Field5_0' name='Field5' />
                <label for="Field5_0">First Choice</label>
              </div>
              <div class='input-holder'>
                <input type="radio" value='First Choice' id='Field5_1' name='Field5' />
                <label for="Field5_1">Second Choice</label>
              </div>
              <div class='input-holder'>
                <input type="radio" value='First Choice' id='Field5_2' name='Field5' />
                <label for="Field5_2">Third Choice</label>
              </div>
            
          </div>

          <div class='table-row'>
            <legend>Check all that apply</legend>
            <div class='input-holder'>
              <input type='checkbox' value='First Choice' id='Field6' name='Field6' />
              <label for="Field6">First Choice</label>
            </div>
            <div class='input-holder'>
              <input type='checkbox' value='Second Choice' id='Field7' name='Field7' />
              <label for="Field7">Second Choice</label>
            </div>
            <div class='input-holder'>
              <input type='checkbox' value='Third Choice' id='Field8' name='Field8' />
              <label for="Field8">Third Choice</label>
            </div>
          </div>
          <div class=='table-row'>
            <label for='Field9' class='center'>Select a Choice</label>
            <div class='input-holder'>
              <select id='Field9' name='Field9'>
                <option value='First Choice'>Option 1</option>
                <option value='Second Choice'>Option 2</option>
                <option value='Third Choice'>Option 3</option>
                <option value='Fourth Choice'>Option 4</option>
                <option value='Fifth Choice'>Option 5</option>
              </select>
            </div>
        </form>

*/

input[type='text']{
  width:100%;
  height:40px;
  font-size:20px;
  padding:10px 15px;
  line-height: 20px;
}

select{
  width:100%;
  height:50px;
  font-size:20px;
  padding:10px 15px;
  line-height: 20px;
  @include bp(max-width, $bp-small){
    appearance: none;
    background:none;
  }
}

input[type='submit']{
  @include btn;
  margin:10px 0;
}
 

.table-row{
  margin-bottom: 20px;
  clear: both;
  margin: 0 0 10px;
  overflow: hidden;
  padding: 1px;
}

.table-row > label, legend{
    float: left;
    padding-right: 25px;
    width: 25%;
    text-align: left;
}

label.center{
  line-height: 40px;
}


/***** Tables***/

.c-w1 table{
  margin:30px 0;
  @include font-size(1.5);
  line-height: 1.73em;
  border-top:5px solid $prim-colour;

  tr{
    border-bottom:1px solid $prim-colour;
  } 
  th{
    padding:10px;
    text-transform: uppercase;
    font-weight: 700;
  }
  td{
    padding:10px;
  }

  caption{
    text-transform: uppercase;
    color:$prim-colour;
    @include font-size(1.8);
    font-weight: 700;
    padding-bottom:10px;

  }

}

/*doc
---
title: Text Links
name: text_links
category: Base CSS
---

Basic link styles.

<p><a href='thelink'>This is a link</a></p>
<p><a href='#'>This is a visited Link</a></p>



*/

/* Remember: LoVe For Hate */
a {
  transition:all .5s;
  color:$prim-colour;
  text-decoration:underline;
}
a:visited {
  
}
a:active {}
a:hover,a:focus { text-decoration: none;}

/**********************************
Helpers
**********************************/

.mobile{
  display: none;
  @include bp(max-width, $bp-small){
    display: block;
  }
}

/**********************************
Carousels (responsive slides)
**********************************/

.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  }

.rslides li {
  -webkit-backface-visibility: hidden;
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0;
  }


.rslides img {
  display: block;
  height: auto;
  float: left;
  width: 100%;
  border: 0;
  }
